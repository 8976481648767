<template>
	<v-card
		elevation="0"
		:loading="loadingData"
		class="ma-0 pa-0"
	>
		<v-card-text class="pl-0 py-4">
			<v-row justify="space-between">
				<v-col cols="auto" class="py-0">
					<v-alert
						dense
						outlined
						border="left"
						:value="errorOn"
						:type="errorType"
						class="ma-0 py-1"
					>
						{{ errorMessage }}
						<font v-if="!isLoaded" @click="getInterviewAnswer" style="cursor:pointer;"><strong>, click here</strong> to reload</font>
					</v-alert>
				</v-col>
				
				<v-col cols="auto" class="text-right py-0 mb-1">
					<v-tooltip bottom nudge-bottom="-15">
						<template v-slot:activator="{ on: onSave }">
							<v-icon
								size="30"
								:color="hoverSave? 'blue darken-1' : 'blue darken-2'"
								v-on="onSave"
								@mouseover="hoverSave = !hoverSave"
								@mouseleave="hoverSave = !hoverSave"
								@click="saveQuestionnaire()"
							>mdi-content-save</v-icon>
						</template>
						<small>Save</small>
					</v-tooltip>
					
					<v-tooltip bottom nudge-bottom="-15">
						<template v-slot:activator="{ on: onRefresh }">
							<v-icon
								size="30"
								:color="hoverRefresh? 'blue darken-1' : 'blue darken-2'"
								v-on="onRefresh"
								@mouseover="hoverRefresh = !hoverRefresh"
								@mouseleave="hoverRefresh = !hoverRefresh"
								@click="getInterviewAnswer"
							>mdi-refresh-circle</v-icon>
						</template>
						<small>Refresh</small>
					</v-tooltip>
				</v-col>
			</v-row>
		</v-card-text>
		
		<v-card-text class="pa-0 ma-0">
			<v-form ref="form">
				<v-simple-table :height="userFormHeight - 40">
					<template v-slot:default>
						<v-row dense no-gutters>
							<v-col cols="12">
								{{ question1 }}
							</v-col>
							
							<v-col cols="12" sm="12" md="10" lg="7" xl="5" class="pl-7 pr-2">
								<v-textarea
									auto-grow
									clearable
									label="State your reason here"
									v-model="userAnswer.answer1Details"
									rows="1"
									row-height="10"
								></v-textarea>
							</v-col>
						</v-row>
						
						<v-row dense no-gutters>
							<v-col cols="12">
								{{ question2 }}
							</v-col>
							
							<v-col cols="auto" class="pl-7">
								<v-radio-group :label="question2Sub1" dense v-model="userAnswer.answer2Sub1" :rules="rulesAnswer2Sub1" :mandatory="false" row class="mt-0">
									<v-radio
										label="Yes"
										value='1'
									></v-radio>
									<v-radio
										label="No"
										value='0'
									></v-radio>
								</v-radio-group>
							</v-col>
							
							<v-col cols="auto" class="pl-7">
								<v-radio-group :label="question2Sub2" dense v-model="userAnswer.answer2Sub2" :rules="rulesAnswer2Sub2" :mandatory="false" row class="mt-0">
									<v-radio
										label="Yes"
										value="1"
									></v-radio>
									<v-radio
										label="No"
										value="0"
									></v-radio>
								</v-radio-group>
							</v-col>
						</v-row>
						
						<v-row dense no-gutters>
							<v-col cols="auto" class="pl-7">
								<v-radio-group :label="question2Sub3" dense v-model="userAnswer.answer2Sub3" :rules="rulesAnswer2Sub3" :mandatory="false" row class="mt-0">
									<v-radio
										label="Yes"
										value="1"
									></v-radio>
									<v-radio
										label="No"
										value="0"
									></v-radio>
								</v-radio-group>
							</v-col>
							
							<v-col cols="auto" class="pl-7">
								<v-radio-group :label="question2Sub4" dense v-model="userAnswer.answer2Sub4" :rules="rulesAnswer2Sub4" :mandatory="false" row class="mt-0">
									<v-radio
										label="Yes"
										value="1"
									></v-radio>
									<v-radio
										label="No"
										value="0"
									></v-radio>
								</v-radio-group>
							</v-col>
						</v-row>
						
						<v-row dense no-gutters>
							<v-col cols="12">
								{{ question3 }}
							</v-col>
							
							<v-col cols="12" class="pl-7">
								<v-radio-group v-model="userAnswer.answer3" dense :rules="rulesAnswer3" @change="answer3Change(); $refs.answer3Details.validate()" :mandatory="false" row class="mt-0">
									<v-radio
										label="Yes"
										value="1"
									></v-radio>
									<v-radio
										label="No"
										value="0"
									></v-radio>
								</v-radio-group>
							</v-col>
							
							<v-col cols="12" sm="12" md="10" lg="7" xl="5" class="pl-7 pr-2">
								<v-textarea
									ref="answer3Details"
									v-model="userAnswer.answer3Details"
									auto-grow
									clearable
									:disabled="userAnswer.answer3 != 1"
									label="If Yes, please briefly state the details:"
									rows="1"
									row-height="10"
									:rules="rulesAnswer3Details"
									validate-on-blur
								></v-textarea>
							</v-col>
						</v-row>
						
						<v-row dense no-gutters>
							<v-col cols="12">
								{{ question4 }}
							</v-col>
							
							<v-col cols="12" class="pl-7">
								<v-radio-group v-model="userAnswer.answer4" dense :rules="rulesAnswer4" :mandatory="false" row class="mt-0">
									<v-radio
										label="Yes"
										value="1"
									></v-radio>
									<v-radio
										label="No"
										value="0"
									></v-radio>
								</v-radio-group>
							</v-col>
							
							<v-col cols="12">
								{{ question5 }}
							</v-col>
							
							<v-col cols="12" class="pl-7">
								<v-radio-group v-model="userAnswer.answer5" dense :rules="rulesAnswer5" @change="answer5Change(); $refs.answer5Details.validate()" :mandatory="false" row class="mt-0">
									<v-radio
										label="Yes"
										value="1"
									></v-radio>
									<v-radio
										label="No"
										value="0"
									></v-radio>
								</v-radio-group>
							</v-col>
							
							<v-col cols="12" sm="12" md="10" lg="7" xl="5" class="pl-7 pr-2">
								<v-textarea
									ref="answer5Details"
									v-model="userAnswer.answer5Details"
									auto-grow
									clearable
									:disabled="userAnswer.answer5 != 1"
									label="If Yes, please briefly state the details:"
									rows="1"
									row-height="10"
									:rules="rulesAnswer5Details"
									validate-on-blur
								></v-textarea>
							</v-col>
						</v-row>
						
						<v-row dense no-gutters>
							<v-col cols="12">
								{{ question6 }}
							</v-col>
							
							<v-col cols="12" class="pl-7">
								<v-radio-group v-model="userAnswer.answer6" dense :rules="rulesAnswer6" @change="answer6Change(); $refs.answer6Details.validate()" :mandatory="false" row class="mt-0">
									<v-radio
										label="Yes"
										value="1"
									></v-radio>
									<v-radio
										label="No"
										value="0"
									></v-radio>
								</v-radio-group>
							</v-col>
							
							<v-col cols="12" sm="12" md="10" lg="7" xl="5" class="pl-7 pr-2">
								<v-textarea
									ref="answer6Details"
									v-model="userAnswer.answer6Details"
									auto-grow
									clearable
									:disabled="userAnswer.answer6 != 1"
									label="If Yes, please briefly state the details:"
									rows="1"
									row-height="10"
									:rules="rulesAnswer6Details"
									validate-on-blur
								></v-textarea>
							</v-col>
						</v-row>
						
						<v-row dense no-gutters>
							<v-col cols="12">
								{{ question7 }}
							</v-col>
							
							<v-col cols="12" class="pl-7">
								<v-radio-group v-model="userAnswer.answer7" dense :rules="rulesAnswer7" @change="answer7Change(); $refs.answer7Days.validate(); $refs.answer7Cause.validate()" :mandatory="false" row class="mt-0">
									<v-radio
										label="Yes"
										value="1"
									></v-radio>
									<v-radio
										label="No"
										value="0"
									></v-radio>
								</v-radio-group>
							</v-col>
							
							<v-col cols="12" sm="12" md="10" lg="7" xl="5" class="pl-7">
								<v-text-field
									ref="answer7Days"
									v-model="userAnswer.answer7Days"
									label="Number of number days incapacitated"
									:disabled="userAnswer.answer7 != 1"
									:rules="rulesAnswer7Days"
									v-mask="incapacitatedDays"
								></v-text-field>
							</v-col>
						</v-row>
						
						<v-row dense no-gutters>
							<v-col cols="12" sm="12" md="10" lg="7" xl="5" class="pl-7">
								<v-text-field
									ref="answer7Cause"
									v-model="userAnswer.answer7Cause"
									label="Cause"
									:disabled="userAnswer.answer7 != 1"
									:rules="rulesAnswer7Cause"
								></v-text-field>
							</v-col>
						</v-row>
						
						<v-row dense no-gutters>
							<v-col cols="12" class="pr-2 font-weight-bold">
								<small>{{ applicantNotice }}</small>
							</v-col>
						</v-row>
					</template>
				</v-simple-table>
			</v-form>
			
			<v-dialog
				v-model="loadingUpdate"
				max-width="355"
				persistent
			>
				<v-card color="primary" dark>
					<v-card-text class="pt-2">
						Saving...
						<v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
					</v-card-text>
				</v-card>
			</v-dialog>
			
		</v-card-text>
	</v-card>
</template>

<script>
import { CLEAR_ALERT } from '@/store/actions/user';

import {mask} from 'vue-the-mask';
import { mapGetters } from 'vuex';

var timeoutData = null;

export default {
	directives: {mask},
	data () {
		return {
			hoverRefresh		: false,
			hoverSave			: false,
			
			//loading status
			isLoaded			: false,
			isLoading			: false,
			loadingData			: false,
			loadingUpdate		: false,
			
			question1			: '1.) Reason for leaving previous Manning Agency',
			question2			: '2.) Ever involved in:',
			question2Sub1		: 'Collision?',
			question2Sub2		: 'Fire?',
			question2Sub3		: 'Grounding?',
			question2Sub4		: 'Abandonement?',
			question3			: '3.) Ever involved in any legal dispute or claim arising from Criminal Case, Civil Case or Labor Case?',
			question4			: '4.) Ever deported from any country?',
			question5			: '5.) Ever repatriated due to Dismissal, Mutual Consent, Resignation or Own Request?',
			question6			: '6.) Ever suffered or are you currently suffering from any Medical Condition requiring medication and/or treatment?',
			question7			: '7.) Ever suffered from injury or illness while onboard and/or Signed-Off/Repatriated for Medical Reasons',
			
			userAnswer: [],
			dafaultAnswer: {
				answer1Details		: '',
				answer2Sub1			: '',
				answer2Sub2			: '',
				answer2Sub3			: '',
				answer2Sub4			: '',
				answer3				: '',
				answer3Details		: '',
				answer4				: '',
				answer5				: '',
				answer5Details		: '',
				answer6				: '',
				answer6Details		: '',
				answer7				: '',
				answer7Days			: '',
				answer7Cause		: '',
			},
			
			answer3DetailsOld		: '',
			answer5DetailsOld		: '',
			answer6DetailsOld		: '',
			answer7DaysOld			: '',
			answer7CauseOld			: '',
			
			incapacitatedDays		: '####',
			
			applicantNotice		: 'NOTICE TO THE APPLICANT: You are required to honestly and truthfully disclose all the required information and details in this form. Any misrepresentation or untruthful statement on your part would automatically disqualify you from employment without prejudice to criminal, civil, and/or administrative prosecution.',
		
			rulesAnswer1Details: [
				v => !!v || 'Must be atleast 2 characters',
			],
			rulesAnswer2Sub1: [
				v => !!v || 'Please select your answer',
			],
			rulesAnswer2Sub2: [
				v => !!v || 'Please select your answer',
			],
			rulesAnswer2Sub3: [
				v => !!v || 'Please select your answer',
			],
			rulesAnswer2Sub4: [
				v => !!v || 'Please select your answer',
			],
			rulesAnswer3: [
				v => !!v || 'Please select your answer',
			],
			rulesAnswer3Details: [
				v => ((!!v && this.answerBoolean(this.userAnswer.answer3)) || !this.answerBoolean(this.userAnswer.answer3)) || 'This field is required',
			],
			rulesAnswer4: [
				v => !!v || 'Please select your answer',
			],
			rulesAnswer5: [
				v => !!v || 'Please select your answer',
			],
			rulesAnswer5Details: [
				v => ((!!v && this.answerBoolean(this.userAnswer.answer5)) || !this.answerBoolean(this.userAnswer.answer5)) || 'This field is required',
			],
			rulesAnswer6: [
				v => !!v || 'Please select your answer',
			],
			rulesAnswer6Details: [
				v => ((!!v && this.answerBoolean(this.userAnswer.answer6)) || !this.answerBoolean(this.userAnswer.answer6)) || 'This field is required',
			],
			rulesAnswer7: [
				v => !!v || 'Please select your answer',
			],
			rulesAnswer7Days: [
				v => ((!!v && this.answerBoolean(this.userAnswer.answer7)) || !this.answerBoolean(this.userAnswer.answer7)) || 'This field is required',
			],
			rulesAnswer7Cause: [
				v => ((!!v && this.answerBoolean(this.userAnswer.answer7)) || !this.answerBoolean(this.userAnswer.answer7)) || 'This field is required',
			],
		}
	},
	methods: {
		getInterviewAnswer () {
			if(this.loadingData && typeof source !== 'undefined')source.cancel();
			
			const CancelToken = this.$http.CancelToken;
			let source = CancelToken.source();
			
			this.$store.dispatch(CLEAR_ALERT);
			this.loadingData = true;
			this.isLoaded = false;
			
			clearTimeout(timeoutData);
				
			this.$http.get('interview', { cancelToken: source.token })
			.then((resp) => {
				this.userAnswer = (Object.keys(resp.data).length != 0)? resp.data : this.userAnswer = this.dafaultAnswer;
				this.$refs.form.resetValidation();
				this.isLoaded = true;
			})
			.catch(() => {
				timeoutData = setTimeout(() => {
					this.getInterviewAnswer();
				}, 60000);
			})
			.finally(() => {
				this.loadingData = false;
			});
		},
		saveQuestionnaire () {
			if(this.$refs.form.validate()){
				this.$store.dispatch(CLEAR_ALERT);
				this.loadingUpdate = true;
				
				this.$http.put('interview', {
					forms: this.userAnswer
				})
				.finally(() => {
					this.loadingUpdate = false;
				});
			}
		},
		
		answer3Change () {
			if( this.userAnswer.answer3 != 1 ){
				this.answer3DetailsOld = this.userAnswer.answer3Details;
				
				this.userAnswer.answer3Details = '';
			}else{
				this.userAnswer.answer3Details = this.answer3DetailsOld;
			}
		},
		answer5Change () {
			if( this.userAnswer.answer5 != 1 ){
				this.answer5DetailsOld = this.userAnswer.answer5Details;
				
				this.userAnswer.answer5Details = '';
			}else{
				this.userAnswer.answer5Details = this.answer5DetailsOld;
			}
		},
		answer6Change () {
			if( this.userAnswer.answer6 != 1 ){
				this.answer6DetailsOld = this.userAnswer.answer6Details;
				
				this.userAnswer.answer6Details = '';
			}else{
				this.userAnswer.answer6Details = this.answer6DetailsOld;
			}
		},
		answer7Change () {
			if( this.userAnswer.answer7 != 1 ){
				this.answer7DaysOld = this.userAnswer.answer7Days;
				this.answer7CauseOld = this.userAnswer.answer7Cause;
				
				this.userAnswer.answer7Days = '';
				this.userAnswer.answer7Cause = '';
			}else{
				this.userAnswer.answer7Days = this.answer7DaysOld;
				this.userAnswer.answer7Cause = this.answer7CauseOld;
			}
		},
		
		stringLength(text){
			return (typeof text === 'undefined' || text === null)? 0 : text.length;
		},
		answerBoolean(val){
			return (typeof val !== 'undefined' && val == 1)? true : false;
		},
	},
	computed:{
		...mapGetters(['userFormHeight', 'errorOn', 'errorType', 'errorMessage'])
	},
	created () {
		this.getInterviewAnswer();
	},
	beforeDestroy(){
		clearTimeout(timeoutData);
	},
}

</script>

<style scoped>
	.uppercase-input >>> input {
		text-transform: uppercase
    }
</style>